//
// _footer.scss
//
@media screen and (max-width: 767px) {
  .footer {
    width: calc(100% - 250px);
  }
}
.footer {
  bottom: 0;
  padding: 20px calc(#{$grid-gutter-width} * 0.75);
  position: absolute;
  right: 0;
  color: $footer-color;
  left: 250px;
  height: $footer-height;
  transition: all 1s ease-in-out;
  background-color: $footer-bg;
  width: calc( 100% - 250px);
  @media (max-width: 991.98px) {
    left: $sidebar-collapsed-width;
    width: calc( 100% - #{$sidebar-collapsed-width});
  }
}

// Enlarge menu
.vertical-collpsed {
  .footer {
    left: $sidebar-collapsed-width;
    width: calc(100% - #{$sidebar-collapsed-width});
    right: 0;
    // @media (max-width: 991.98px) {
    //   left: 0;
    // }
  }
}

body[data-layout="horizontal"] {
  .footer {
    left: 0 !important;
  }
}
