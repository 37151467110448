.list-inline{
    // background: #000;
    .list-inline-item{
        margin: 0 13px;
        .social-list-item{
            display: flex;
            align-items: center;
            justify-content: center;
            border: none;
            i{
                font-size: 20px;
            }
        }
    }
}