.spinner_loader{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10000;
    background-color: #00000088;
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(5px);
    &.oval_loader{
        position: absolute !important;
        background-color: rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(2px);
    }
}

.chart_card_loading{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10000;
    border-radius: 10px;
    background-color: #00000088;
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(5px);
}