.shipment-confirmation {

  .delivery-partner {
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
  }

  .title {
    font-size: 14px;
    font-weight: 500;
  }

  .box-details {
    background: rgba(85, 110, 230, 0.15);
    border: 2px solid #556ee6;
  }

  .title-width {
    min-width: 100px;
  }

  .element-width {
    width: 100px;
  }

  .div-details {
    flex: 1;
  }

  .custom-p-spacing {
    margin: 6px 0;
  }

  .customer-detail {
    margin-top: 0 !important;
    padding: 0;
    text-align: right;
    margin-left: auto;
    font-size: 14px;
    font-weight: 500;
  }

  .align-items-start {
    align-items: flex-start;
  }

  .delivery-partner-image {
    margin-top: 10px;
    max-width: 27%;
    height: auto;
  }

  .dpd-delivery-partner-image {
    margin-top: 10px;
    max-width: 20%;
    height: auto;
  }

  .jos-delivery-partner-image {
    margin-top: 10px;
    max-width: 50%;
    height: auto;
  }

  .header {
    background: #EFF2F6;
  }

  .d-flex {
    display: flex;
  }

  .address {
    padding: 7px;
  }

  .main-card {
    padding: 16px;
    border: 1px solid rgb(173, 173, 173);

    .product-section {

      .product-info {
        background-color: #EFF2F6;
        padding: 16px;
      }

      .package-info {
        padding: 0px 4px 0px 4px;

        @media (max-width: 1024px) {
          overflow: auto;
        }
        @media (max-width: 1199px) {
          overflow: auto;
        }
      }
    }
  }

  .scroll-details {
    overflow-y: auto;
    max-height: 1138px;
  }

  .form-row {
    display: flex;
    align-items: flex-start;
    gap: 20px;
  }

  .form-group {
    flex: 1;
    min-width: 100px;
  }

  .ml-2 {
    margin-left: 10px;
  }
  .mt-2 {
    margin-top: 10px;
  }

  .color-blue {
    color: blue;
  }
}
